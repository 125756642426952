<style lang="scss">
    .tooltip-body {
        padding: 0.3rem 0.5rem;
        width: 25rem;
    }
    dl {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        margin: 0;
        overflow: visible;
        width: 100%;
    }
    dt {
        flex: 0 0 30%;
        text-align: right;
    }
    dd {
        flex: 0 0 70%;
        margin-inline-start: 0;
        padding-left: 1rem;
        text-align: left;
    }
    code {
        word-spacing: -0.6ch;
    }
</style>

<div class="wowthing-tooltip">
    <h4>Character Filters</h4>

    <div class="tooltip-body">
        <dl>
            <dt>Name</dt>
            <dd>
                <code>momo | steve | ...</code>
            </dd>

            <dt>Realm</dt>
            <dd>
                <code>realm=malganis | realm=kirin-tor | ...</code>
            </dd>

            <dt>Level</dt>
            <dd>
                <code>level=60 | &lt;=,&lt;,=,&gt;,&gt;=</code>
            </dd>

            <dt>Item Level</dt>
            <dd>
                <code>ilevel&gt;=400 | &lt;=,&lt;,=,&gt;,&gt;=</code>
            </dd>

            <dt>Faction</dt>
            <dd>
                <code>alliance | horde | neutral</code>
            </dd>

            <dt>Race</dt>
            <dd>
                <code>blood-elf | human | ...</code>
            </dd>

            <dt>Class</dt>
            <dd>
                <code>demon-hunter | death-knight | hunter | ...</code>
            </dd>

            <dt>Tank specs</dt>
            <dd>
                <code>tank</code>
            </dd>

            <dt>Healer specs</dt>
            <dd>
                <code>heal | healer | heals</code>
            </dd>

            <dt>DPS specs</dt>
            <dd>
                <code>dps | deeps</code>
            </dd>

            <dt>Armor type</dt>
            <dd>
                <code>cloth | leather | mail | plate</code>
            </dd>

            <dt>Profession</dt>
            <dd>
                <code>alchemy | blacksmithing | ...</code>
            </dd>

            <dt>Account tag</dt>
            <dd>
                <code>accountTag=[tag]</code>
            </dd>

            <dt>Character tag</dt>
            <dd>
                <code>tag=[tag]</code>
            </dd>

            <dt>Has M+ score</dt>
            <dd>
                <code>m+</code>
            </dd>

            <dt>Has work orders</dt>
            <dd>
                <code>orders</code>
            </dd>
        </dl>
    </div>
</div>
