<style lang="scss">
    td {
        background: $body-background !important;
        border-width: 0 !important;
    }
</style>

<tr>
    <td colspan="100">&nbsp;</td>
</tr>
