<style lang="scss">
    a {
        background: darken(#0074e0, 15%);
        border: 1px solid #0074e0;
        border-radius: $border-radius;
        color: #fff;
        display: block;
        margin: -0.5rem 0;
        padding: 0.2rem 0.5rem;
    }
</style>

<li>
    <a href="/auth/login">Refresh Character List</a>
</li>
