<script lang="ts">
    export let name: string
    export let options: [number|string, number|string][]
    export let selected: number|string
    export let width = ''
</script>

<style lang="scss">
    select {
        background: $highlight-background;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23BBBBBB%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
        background-position: right .7rem top 50%;
        background-repeat: no-repeat;
        background-size: 1rem auto;
        border-radius: $border-radius;
        height: 2rem;
        max-width: 100%;
        padding: 0 0.5rem;
        width: 100%;
    }
</style>

<fieldset>
    <select
        class="border"
        name={name}
        bind:value={selected}
        style:width
    >
        {#each options as [value, text]}
            <option value="{value}">{text}</option>
        {/each}
    </select>
</fieldset>
