<script lang="ts">
    // import type { AchievementDataAchievement } from '@/types'

    // export let achievement: AchievementDataAchievement
</script>

<style lang="scss">

</style>

<div>
    barr
</div>
