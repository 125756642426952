<style lang="scss">
    th {
        @include cell-width($width-raider-io);

        text-align: center;
        vertical-align: bottom;
    }
</style>

<th>Score</th>
